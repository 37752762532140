<template>
  <v-card
    :style="
      smUp
        ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px'
        : ''
    "
    flat
  >
    <v-layout
      :style="smUp ? 'max-width: 85%; margin-top: 2.5%' : 'margin-top: 2.5%'"
      wrap
      row
      :justify-center="!smUp"
      :justify-start="smUp"
    >
      <v-flex xs12 sm12>
        <v-layout row :justify-center="!smUp" :justify-start="smUp">
          <p v-text="'Estadísticas'" class="view_title_text" />
        </v-layout>
      </v-flex>
      <v-flex sm6>
        <v-layout column>
          <v-flex pa-3>
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <p class="view_title_text">Personas interesadas</p>
              <div id="chart">
                <apexchart
                  ref="realtimeChart1"
                  height="400"
                  type="bar"
                  :options="options"
                  :series="series"
                  :distributed="true"
                />
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex sm6>
        <v-layout column>
          <v-flex pa-3>
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <p class="view_title_text">Solicitud de clientes</p>
              <apexchart
                id
                type="pie"
                ref="realtimeChart"
                :options="optionsPieGender"
                :series="seriesPieGender"
              ></apexchart>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex sm12>
        <v-layout row wrap>
          <v-flex sm6 pa-3>
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <v-layout row wrap>
                <v-flex sm10>
                  <p class="view_title_text" style="font-size: 20px">
                    Estados de la república con más solicitudes
                  </p>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-for="(item, index) in products_list"
                :key="index"
              >
                <v-flex sm2>
                  <p style="font-weight: 500">{{ index + 1 }}</p>
                </v-flex>
                <v-flex sm7>
                  <p style="font-weight: 500">{{ item._id }}</p>
                </v-flex>
                <v-flex sm3>
                  <p style="font-weight: 500">{{ item.count }}</p>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex sm6 pa-3>
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <v-layout row wrap>
                <v-flex sm10>
                  <p class="view_title_text" style="font-size: 20px">
                    Estados de la república con menos solicitudes
                  </p>
                </v-flex>
                <v-flex sm2>
                  <v-icon style="color: #ff5f5f">mdi-arrow-down</v-icon>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-for="(item, index) in products_list_worst"
                :key="index"
              >
                <v-flex sm2>
                  <p style="font-weight: 500">{{ index + 1 }}</p>
                </v-flex>
                <v-flex sm7>
                  <p style="font-weight: 500">{{ item._id }}</p>
                </v-flex>
                <v-flex sm3>
                  <p style="font-weight: 500">{{ item.count }}</p>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex sm6 pa-3 v-if="false">
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <v-layout row wrap>
                <v-flex sm10>
                  <p class="view_title_text" style="font-size: 20px">
                    Kits más vendidos
                  </p>
                </v-flex>
                <v-flex sm2>
                  <v-icon style="color: #34bf9a">mdi-arrow-up</v-icon>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-for="(item, index) in products_list"
                :key="index"
              >
                <v-flex sm2>
                  <p style="font-weight: 500">{{ index + 1 }}</p>
                </v-flex>
                <v-flex sm7>
                  <p style="font-weight: 500">{{ item.name }}</p>
                </v-flex>
                <v-flex sm3>
                  <p style="font-weight: 500">{{ item.quantity }}</p>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex sm6 pa-3 v-if="false">
            <v-card
              style="
                padding: 30px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 15px;
              "
            >
              <v-layout row wrap>
                <v-flex sm10>
                  <p class="view_title_text" style="font-size: 20px">
                    Kits menos vendidos
                  </p>
                </v-flex>
                <v-flex sm2>
                  <v-icon style="color: #ff5f5f">mdi-arrow-down</v-icon>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-for="(item, index) in products_list"
                :key="index"
              >
                <v-flex sm2>
                  <p style="font-weight: 500">{{ index + 1 }}</p>
                </v-flex>
                <v-flex sm7>
                  <p style="font-weight: 500">{{ item.name }}</p>
                </v-flex>
                <v-flex sm3>
                  <p style="font-weight: 500">{{ item.quantity }}</p>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    menu15: false,
    menu16: false,
    total_users: "",
    dateFormattedInicial: null,
    dateInicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormattedFinal: null,
    dateFinal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    seriesPieGender: [100, 0],
    series2: [50, 0],
    optionsPieGender: {
      chart: {
        type: "pie",
        height: 350,
      },
      labels: ["Hombres", "Mujeres"],
      colors: ["#00B1FF", "#FFB7E3"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    client_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    products_list: [],
    products_list_worst: [],
    add_client: false,
    bLoading: false,
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      xaxis: {
        categories: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        labels: {
          style: {
            colors: [
              "#B486B4",
              "#E28787",
              "#5BB4B4",
              "#FEBC93",
              "#BCD86E",
              "#F7D774",
              "#D0E7FA",
              "#D0CC6C",
              "#C6B6D5",
              "#9CB580",
              "#C77274",
              "#6CBBE5",
            ],
          },
        },
      },
      yaxis: {
        title: {
          text: "",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        labels: {
          style: {
            color: "white",
          },
          formatter: (val) => {
            return val.toFixed(2) + "";
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return "";
        },
        dropShadow: {
          enabled: true,
        },
      },
      tooltip: {
        theme: "light",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (value) {
              return "";
            },
          },
        },
      },
      colors: [
        "#B486B4",
        "#E28787",
        "#5BB4B4",
        "#FEBC93",
        "#BCD86E",
        "#F7D774",
        "#D0E7FA",
        "#D0CC6C",
        "#C6B6D5",
        "#9CB580",
        "#C77274",
        "#6CBBE5",
      ],
      plotOptions: {
        stroke: {
          width: 1,
          colors: [
            "#B486B4",
            "#E28787",
            "#5BB4B4",
            "#FEBC93",
            "#BCD86E",
            "#F7D774",
            "#D0E7FA",
            "#D0CC6C",
            "#C6B6D5",
            "#9CB580",
            "#C77274",
            "#6CBBE5",
          ],
        },
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "top",
            style: {},
            colors: [
              "#B486B4",
              "#E28787",
              "#5BB4B4",
              "#FEBC93",
              "#BCD86E",
              "#F7D774",
              "#D0E7FA",
              "#D0CC6C",
              "#C6B6D5",
              "#9CB580",
              "#C77274",
              "#6CBBE5",
            ],
            dropShadow: {
              enabled: true,
            },
            offsetX: 0,
          },
        },
      },
      months: [],
    },
  }),
  watch: {
    dateInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
    },
    dateFormattedInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
      this.getOrders();
    },
    dateFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
    },
    dateFormattedFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
      this.getOrders();
    },
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    client_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      let that = this;
      var data = "";
      var config = {
        method: "get",
        url: `${uri}api/contacts/stats`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.usr_token,
        },
        data: data,
      };

      db(config)
        .then(function (response) {
          console.log("here");
          console.log(response.data);
          that.products_list = response.data.stateCount.mostStates;
          that.products_list_worst = response.data.stateCount.lessStates;
          that.total_users = response.data.genderCount.total;
          var holder;

          that.seriesPieGender = [
            parseInt(response.data.genderCount.males),
            parseInt(response.data.genderCount.females),
          ];

          for (
            let index = 0;
            index < response.data.interestedPeople.length;
            index++
          ) {
            that.series[0].data[response.data.interestedPeople[index]._id-1] =
              response.data.interestedPeople[index].count;
          }

          that.$refs.realtimeChart1.updateSeries(
            [
              {
                data: that.series[0].data,
              },
            ],
            false,
            true
          );

          that.$refs.realtimeChart.chart.resetSeries();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getOrders() {
      let that = this;
      var data = "";
      var config = {
        method: "get",
        url: `${uri}/statistics?startDate=${that.dateInicial}&endDate=${that.dateFinal}`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTc5NDE5NjM5NTEsImlkIjoiMmJmMjNkYmYtMDhkMC00NTM2LWI2M2MtZGNhMTNmYzIzYjA0Iiwicm9sZSI6OTl9._RgakrkoTIsAeJkmumLXhytewFk96_L7QGrfpxTHKXU",
        },
        data: data,
      };

      db(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          that.products_list = response.data.topRecipes;
          that.products_list_worst = response.data.worstRecipes;
          that.total_users = response.data.totalUsers[0].count;
          var holder;

          that.seriesPieGender = [
            response.data.menWomen[0].count,
            response.data.menWomen[1].count,
          ];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    get_clients() {},
    get_clients_search() {},
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    apexchart,

  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
</style>